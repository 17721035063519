import React from "react"
import { ServicePageLayout, layoutColorTypes } from "../../../layouts/service-page/service-page.layout";
import { getCanonicalUrl } from "../../../utils/getCanonicalUrl"

import { testimonials } from "./testimonials"
import { getStaticImgUrl } from "../../../utils/getStaticImgUrl";

const content = {
  title: "Academic Writing Services",
  whatWeOffer: {
    leftPart: "Academic studies are very sophisticated nowadays. Having to attend lectures and seminars, study for exams, and organize yourself is hard enough. On top of that, you have a ton of academic writing tasks you have no time for.",
    rightPart: "We are here to help you with this. At Podroom Creative, we can help you with all your academic writing needs. You might be an expert in your field, but this form of writing requires a unique skill set, which our writers have perfected."
  },
  processText: "Contact us right away, and let's discuss your specific needs. Our team of qualified writers will take care of the academic writing task so that you can focus on your studies.",
  testimonials: testimonials
}

const meta = {
  title: "Academic Writing Services",
  description: "Academic studies are very sophisticated nowadays. Having to attend lectures and seminars, study for exams, and organize yourself is hard enough. On top of that, you have a ton of academic writing tasks you have no time for."
}

const og = {
  image: getStaticImgUrl("img_contnet_writing_full.jpg")
}

const pageSlug = "content-services/academic-writing";

export default () => (

  <ServicePageLayout 

    meta={meta}
    og={og}    
    layoutColorType={layoutColorTypes.YELLOW}
    rightHeroImage={"/img_contnet_writing_full.jpg"}
    headerIcon={"/ico_content_writing_2@2x.png"}
    content={content}
    canonicalUrl={getCanonicalUrl(pageSlug)}

  />

)